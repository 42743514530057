/**
 * 像素工具类
 */

/**
 * 选择销售国家事件
 */
export function selectCountry(data){
    //fbq('track', 'CustomEvent',data);
    fbq('trackCustom', 'selectCountry', data);
}

/**
 * 联系客服
 */
export function talkToEmployee(data){
    //fbq('track', 'CustomEvent',data);
    fbq('trackCustom', 'talkToEmployee', data);
    //电话、短信、邮件、聊天或客户与商家之间的其他联系方式。
    //fbq('track', 'Contact');
}

/**
 * 点击购物车
 */
export function clickMyCar(data){
    //fbq('track', 'CustomEvent',data);
    fbq('trackCustom', 'clickMyCar', data);
    // 将商品加入购物车或购物篮。例如：在网站中点击“加入购物车”按钮。
    //fbq('track', 'AddToCart');
}

/**
 * 点击提交订单
 */
export function clickSubmitOrder(data){
    //fbq('track', 'CustomEvent',data);
    fbq('trackCustom', 'clickSubmitOrder', data);
    // 在结账流程中添加客户支付信息。例如，用户点击保存账单信息按钮。
    //fbq('track', 'AddPaymentInfo');
}


/**
 * 点击确认订单
 */
export function checkThisOrder(data){
    //fbq('track', 'CustomEvent',data);
    fbq('trackCustom', 'checkThisOrder',data);
    //用户进入结账流程。例如，点击“结账”按钮。
    //fbq('track', 'InitiateCheckout');
}

/**
 * 点击订单确认结果
 */
export function endOrder(data){
    //fbq('track', 'CustomEvent',data);
    fbq('trackCustom', 'endOrder', data);
    //用户进入结账流程。例如，点击“结账”按钮。
    //fbq('track', 'Purchase',data);
}
