<template>
    <div class="pageBox pageBox-main-box">
        <noscript>
            <img height="1" width="1"
                 style="display:none"
                 :src="`https://www.facebook.com/tr?id=`+productInfo.tproducts.facebookMessagerId+`&ev=PageView&noscript=1`"/>
        </noscript>
        <div v-if="openCar.open==false">
            <div class="mainBox">
                <el-carousel :interval="5000" arrow="always" :height="maxHeight+`px`">
                    <el-carousel-item v-for="(k,v) in productInfo.topFiles"  >
                        <el-image :src="k.filePath"  style="width:100%;" @load="handleImageLoad"></el-image>
                    </el-carousel-item>
                </el-carousel>
                <div class="productsName" style="font-size:22px;">
                      {{productInfo.tproducts.productsName}}
                </div>
                <div class="productsFlag">
                    <span>{{showPrice.icon}}</span>
                    <span>{{showPrice.lowerPrice}}</span>

                    <span v-if="language.data">
                      <span  v-for="(k,v) in language.data.flags" v-if="k.use===true" :style="`color:`+k.color+`;margin:0px 2px; padding:0px 2px;background:`+k.gbColor+`;`" >{{k.name}}</span>
                    </span>
<!--                    <span  >
                       <span :style="`color:white;margin:0px 2px; padding:0px 2px;background:red;`">Free Shipping/免运费</span>
                      <span :style="`color:white;margin:0px 2px; padding:0px 2px;background:red;`">Cash on Delivery/货到付款</span>
                      <span :style="`color:white;margin:0px 2px; padding:0px 2px;background:black;`">15天内无理由退换货</span>
                    </span>-->
                </div>

                <hr>
                <!--  -->
                <div v-html="productInfo.tproducts.details" class="details" id="details"></div>
                <el-image v-for="(k,v) in productInfo.detailFiles" :lazy="true" :src="k.filePath"
                          style="display: block;margin-top: -1px;"></el-image>

                <center><b>{{language.data.tips}}</b></center>
                <el-collapse accordion>
                    <el-collapse-item v-for="(k,v) in language.data.userNote">
                      <template slot="title">
                        <div style="padding: 0px 15px;">
                          {{ k.title }}
                        </div>
                      </template>
                      <div style="padding: 0px 20px;">
                        <span v-html="k.content"></span>
                      </div>
                    </el-collapse-item>
<!--                    <el-collapse-item>
                        <template slot="title">
                            <div style="padding: 0px 15px;">
                                用户须知
                            </div>
                        </template>
                        <div style="padding: 0px 20px;">
                            本产品的实际使用效果根据个人情况决定，不保证每位用户都能享受到所宣传的效果。若有疑问请咨询在线客服联络我们，本公司享有最终解释权。
                        </div>
                    </el-collapse-item>

                    <el-collapse-item>
                        <template slot="title">
                            <div style="padding: 0px 15px;">
                                如何申请退换货
                            </div>
                        </template>
                        <div style="padding: 0px 20px;">
                            1.由于个人原因产生的退换货：自收到商品之日起15天内，在不影响二次销售的情况下请联系我们的在线客服，客服会在收到消息后的1-3个工作日内受理您的请求，退换货所产生的运费需自行承担。
                        </div>
                        <div style="padding: 0px 20px;">2.
                            由于质量原因产生的退换货：自收到商品之日起15天内请联系我们的在线客服，客服会在收到邮件后的1-3个工作日内受理您的请求，退换货所产生的运费由我方承担。
                            请注意，为了确保顺利处理退换货请求，请在联系客服时提供订单号、姓名和电话，并详细说明退换货的原因。我们会尽快处理您的请求，以确保您的权益得到保障。
                        </div>
                    </el-collapse-item>

                    <el-collapse-item>
                        <template slot="title">
                            <div style="padding: 0px 15px;">
                                退货流程
                            </div>
                        </template>
                        <div style="padding: 0px 20px;">确认收货—申请退换货—客服审核通过—用户寄回商品—仓库签收验货—退换货审核—退款/换货；</div>
                        <div style="padding: 0px 20px;">退换货请注明：订单号、姓名、电话。</div>
                    </el-collapse-item>

                    <el-collapse-item>
                        <template slot="title">
                            <div style="padding: 0px 15px;">
                                联系信息
                            </div>
                        </template>
                        <div style="padding: 0px 20px;">我们的在线客服服务时间：（UTC/GMT + 08:00）9:00-18:00。我们会在1个工作日内（24小时内）给您回复。
                        </div>
                        <div style="padding: 0px 20px;">温馨提醒：为更有效率地解决问题，请在联系客服团队之前提前准备好订单编号等信息哦！</div>
                        &lt;!&ndash;<div style="padding: 0px 20px;"></div>&ndash;&gt;
                        <div style="padding: 0px 20px;">经营范围：研发、销售：电子产品、电脑周边产品、数码产品、空气过滤器、净化设备、自动化零部件等</div>
                    </el-collapse-item>

                    <el-collapse-item>
                        <template slot="title">
                            <div style="padding: 0px 15px;">
                                隐私政策
                            </div>
                        </template>
                        <div style="padding: 0px 20px;">隐私声明：</div>
                        <div style="padding: 0px 20px;">我们非常重视客户的隐私。因此，我们只会根据本隐私声明所规定的方式收集和使用您的个人资料。</div>
                        <div style="padding: 0px 20px;">1. 我们仅在有必要或与交易相关的情况下收集您的资料。</div>
                        <div style="padding: 0px 20px;">2. 我们仅在法律要求或与收集目的相关的情况下保留您的资料。</div>
                        <div style="padding: 0px 20px;">
                            在您未登录用户账号浏览我们的网站时，您将以匿名身份进行浏览，而我们无法识别您的身份。我们非常欢迎您提供宝贵的意见和建议。如果您有任何意见或建议，请联系客服。
                        </div>
                    </el-collapse-item>-->

                </el-collapse>
            </div>

            <el-backtop :bottom="60" :right="0">
                <div class="toTop">
                    <i class="el-icon-top"></i>
                </div>
            </el-backtop>

            <FooterTip></FooterTip>
            <div class="footerBox pageBox">
                <el-row>
                    <el-col :span="6" class="leftNavBox">
                       <span @click="leftNavAction()">
                            <i class="el-icon-chat-dot-round"></i>
                            {{ language.data.chat }}
                       </span>
                    </el-col>
                    <el-col :span="18" class="rightNavBox">
                        <span @click="rightNavAction()">
                            <i class="el-icon-shopping-cart-full"></i>
                             {{ language.data.car }}
                        </span>
                    </el-col>
                </el-row>
            </div>
        </div>

        <div v-if="openCar.open==true">
            <div class="backBox pageBox" >
                <el-page-header :title="language.data.back" @back="backAction" :content="language.data.car"></el-page-header>
            </div>
            <div class="BuyFormBox">
                <div>
                    <el-row>
                        <el-col :span="6">
                            <el-image fit="cover" :lazy="true" :src="selectFistProductListAndFirstOptionItemData.firstProductList.image || productInfo.topFiles[0].filePath"></el-image>
                        </el-col>
                        <el-col :span="14">
                            <div class="BuyFormBox-productsName" v-html="productInfo.tproducts.productsName"></div>
                        </el-col>
                        <el-col :span="4">
                            <div class="BuyFormBox-presentPrice"
                                 style="font-size: 16px;line-height: 90px;font-weight: bolder;">
                                {{showPrice.icon}}{{showPrice.listPrice}}
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <div class="BuyFormBox-details" v-html="productInfo.tproducts.details"></div>
                        </el-col>
                    </el-row>
                </div>
                <br>
                <div>
                    <div><b>{{ language.data.choice }}：</b></div>
                    <div v-for="(k,v) in productInfo.salesCountryJSON[centerDialogVisible.selectCountry.id].productListItem"
                         :class="(selectFistProductListAndFirstOptionItemData.firstProductList.id==v)?`BuyFormBox-productList BuyFormBox-productList-click`:`BuyFormBox-productList `"
                         @click="productListClickAction(k,v)">
                        <span style="font-size: 16px;font-weight: bolder;">
                            <div style="position: relative;">
                                <el-row>
                                    <el-col :span="21">
                                        {{k.listName}}
                                    </el-col>
                                    <el-col :span="3">
                                        <div :class="(selectFistProductListAndFirstOptionItemData.firstProductList.id==v)?`list-price-click` : `list-price` ">
                                            {{productInfo.salesCountryJSON[centerDialogVisible.selectCountry.id].currency_icon}} {{k.price}}
                                            <span v-if="k.otherAdMessage!=null&&k.otherAdMessage!=undefined" v-for="(item2,index) in k.otherAdMessage.split(',') || '' " style="margin-left: 10px;">
                                              {{item2}}
                                            </span>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </span>
                    </div>
                </div>
                <div  v-for="(k,v) in itemData.data">
                    <div class="BuyFormBox-productList" v-if="(k != null && k != undefined && k.length > 0)">
                        <div style="padding-left:5px;" >
                            <div v-for="(k2,v2) in k" style="margin: 10px 0px 0px 0px;">
                                <div>{{itemData.title[v][v2]}}:</div>
                                <span v-for="(item2,index) in k2"
                                      @click="optionItemClick(v,v2,index)"
                                      :class="(itemData.pos[v][v2][index]==1) ?
                                      `optionItem optionItem-click`:
                                      `optionItem `"
                                >
                                    <el-image v-if="item2.img!=null"
                                              :lazy="true"
                                              style="width:100px;height: 100px;margin: 0px 0px 8px 0px;" fit="cover"
                                              :src="item2.img"></el-image>
                                    <div style="text-align: center;">{{item2.optionItemName}}</div>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>

                <div style="margin-top: 10px;">
                    <el-form ref="$form" :model="userFrom" :inline="false" label-position="left"  label-width="100px"
                             :hide-required-asterisk="false" show-message :inline-message="false" :status-icon="false"
                             :validate-on-rule-change="false" :disabled="false">
                        <el-form-item prop="ding_dan_qian_zhui" :label="language.data.priceTotal"   :required="false"
                                      show-message :inline-message="false">
                            <el-input v-model="showPrice.listPrice" :placeholder="language.data.inputTip+language.data.priceTotal" autocomplete="off"
                                      :readonly="true"
                                      :autofocus="false" type="text" :show-word-limit="false" clearable
                                      :show-password="false"
                                      :disabled="false" validate-event></el-input>
                        </el-form-item>
                      <el-divider></el-divider>
                        <el-form-item prop="ding_dan_qian_zhui" :label="language.data.number" :required="false"
                                      show-message :inline-message="false">
                            <el-input v-model="userFrom.total" :placeholder="language.data.inputTip+language.data.number" autocomplete="off" :readonly="true"
                                      :autofocus="false" type="text" :show-word-limit="false" clearable
                                      :show-password="false"
                                      :disabled="false" validate-event></el-input>
                        </el-form-item>
                      <el-divider></el-divider>
                        <el-form-item prop="ding_dan_qian_zhui" :label="language.data.pay" :required="false"
                                      show-message :inline-message="false">
<!--                            <el-tag type="danger" effect="dark">
                                Cash on Delivery/货到付款
                            </el-tag>-->
                            <el-tag type="danger" style="margin-right: 3px;padding:0px 5px;" effect="dark"
                                    v-if="productInfo.tproducts.deliveryMessage!=null && productInfo.tproducts.deliveryMessage!=undefined"
                                    v-for="item in productInfo.tproducts.deliveryMessage?productInfo.tproducts.deliveryMessage.split(',') : 'Cash on Delivery/货到付款' ">
                              {{item}}
                            </el-tag>
                        </el-form-item>
                      <el-divider></el-divider>
                        <el-form-item prop="name" :label="language.data.name" :required="false"
                                      show-message :inline-message="false">
                            <el-input v-model="userFrom.name" :placeholder="language.data.inputTip+language.data.name" autocomplete="off" :readonly="false"
                                      :autofocus="false" type="text" :show-word-limit="false" clearable
                                      :show-password="false"
                                      :disabled="false" validate-event></el-input>
                        </el-form-item>
                      <el-divider></el-divider>
                        <el-form-item prop="phone" :label="language.data.phone" :required="false"
                                      show-message :inline-message="false">
                            <el-input v-model="userFrom.phone" :placeholder="language.data.inputTip+language.data.phone" autocomplete="off" :readonly="false"
                                      :autofocus="false" type="text" :show-word-limit="false" clearable
                                      :show-password="false"
                                      :disabled="false" validate-event></el-input>
                        </el-form-item>
                      <el-divider></el-divider>
                        <el-form-item prop="email" :label="language.data.email" :required="false"
                                      show-message :inline-message="false">
                            <el-input v-model="userFrom.email" :placeholder="language.data.inputTip+language.data.email" autocomplete="off" :readonly="false"
                                      :autofocus="false" type="text" :show-word-limit="false" clearable
                                      :show-password="false"
                                      :disabled="false" validate-event></el-input>
                        </el-form-item>
                      <el-divider></el-divider>
                        <el-form-item prop="zipCode" :label="language.data.zipCode" :required="false"
                                v-if="centerDialogVisible.selectCountry.id != '1658133405726007298' && centerDialogVisible.selectCountry.id != '1658133367197130754'"
                                show-message :inline-message="false">
                            <el-input v-model="userFrom.zipCode" :placeholder="language.data.inputTip+language.data.zipCode" autocomplete="off" :readonly="false"
                                      :autofocus="false" type="text" :show-word-limit="false" clearable
                                      :show-password="false"
                                      :disabled="false" validate-event></el-input>
                        </el-form-item>
                        <el-divider></el-divider>

                        <el-form-item prop="address" :label="language.data.address" :required="false"
                                      show-message :inline-message="false">
                            <span v-if="productInfo.salesCountryJSON[centerDialogVisible.selectCountry.id].treeNode && productInfo.salesCountryJSON[centerDialogVisible.selectCountry.id].treeNode.length>0">
                                <span>
  <!--                                 <el-cascader
                                       clearable
                                       v-model="userFrom.selectAddress.ids"
                                       :options="productInfo.salesCountryJSON[centerDialogVisible.selectCountry.id].treeNode"
                                       placeholder="请选择地区"
                                       @change="addressSelect"
                                       :props="{value:'id',label:'dictValue',children:'children',leaf:'leaf',checkStrictly: true}"
                                   ></el-cascader>-->
<!--                                    {{userFrom.select1.data}}-->
                                    <el-select v-model="userFrom.select1.select" :placeholder="language.data.choiceAddress+language.data.address" clearable @change="changeAddress1">
                                        <el-option v-for="(k,v) in productInfo.salesCountryJSON[centerDialogVisible.selectCountry.id].treeNode"
                                                :key="k.id"
                                                :value="k.id"
                                                :label="k.dictValue">
                                        </el-option>
                                    </el-select>
                                    <el-select v-if="userFrom.select1.data && userFrom.select1.data.length>0" v-model="userFrom.select2.select" @change="changeAddress2" :placeholder="language.data.choiceAddress+language.data.address" clearable>
                                          <el-option v-for="(k,v) in userFrom.select1.data"
                                                     :key="k.id"
                                                     :value="k.id"
                                                     :label="k.dictValue">
                                          </el-option>
                                    </el-select>
                                    <el-select v-if="userFrom.select2.data && userFrom.select2.data.length>0" v-model="userFrom.select3.select" @change="changeAddress3" :placeholder="language.data.choiceAddress+language.data.address" clearable>
                                          <el-option v-for="(k,v) in userFrom.select2.data"
                                                     :key="k.id"
                                                     :value="k.id"
                                                     :label="k.dictValue">
                                          </el-option>
                                    </el-select>
                                </span>
                            </span>
                            <el-input v-model="userFrom.address" :placeholder="language.data.inputTip+language.data.address" autocomplete="off" :readonly="false"
                                      :autofocus="false" type="text" :show-word-limit="false" clearable
                                      :show-password="false"
                                      :disabled="false" validate-event></el-input>
                            <div class="" style="font-size: 12px;color:#F56C6C;line-height: 15px;">
                              Please be sure to fill in the complete delivery address: city, district, road number and
                              zip code/{{language.data.addressTip}}.
                            </div>
                        </el-form-item>
                        <el-divider></el-divider>

                        <el-form-item prop="ding_dan_qian_zhui" :label="language.data.comment" :required="false"
                                      show-message :inline-message="false">
                            <el-input v-model="userFrom.comment" :placeholder="language.data.inputTip+language.data.comment" autocomplete="off" :readonly="false"
                                      :autofocus="false" type="textarea" :show-word-limit="false" clearable
                                      :show-password="false"
                                      :disabled="false" validate-event></el-input>
                        </el-form-item>
                        <el-divider></el-divider>

                        <div style="font-size: 12px;color:#F56C6C;line-height: 15px;padding: 8px 15px;margin:10px 0px;background-color: rgba(254,240,240,1);border-radius: 8px;">
<!--                            因为运输成本原因，东马地区需要额外加RM30运费，西马地区免运费，敬请理解，祝您购物愉快！Because of the transportation cost, East
                            Malaysia needs an additional RM30 shipping fee, and west malaysia is free shipping. Please
                            understand and wish you a happy shopping!-->
                          <span v-if="productInfo.salesCountryJSON[centerDialogVisible.selectCountry.id].other_money_tips">
                            {{productInfo.salesCountryJSON[centerDialogVisible.selectCountry.id].other_money_tips}}
                          </span>
                        </div>

                        <el-form-item prop="ding_dan_qian_zhui" label="" :required="false" show-message :inline-message="false">
                        </el-form-item>
                        <el-button type="danger" style="width: 100%;height: 50px;" @click="checkOrder">
                            Place Order/{{language.data.submitOrder}}
                        </el-button>
                    </el-form>
                </div>

            </div>
        </div>

        <!--确认订单弹窗-->
        <el-dialog :title="language.data.orderCheckTitle" class="pageBox" :showClose="false"
                   :visible.sync="centerDialogVisible.checkOrderInfo.open" width="90%" center>
            <div class="dialog-content" style="padding: 0px 5px;">
                <el-row>
                    <el-col :span="6">{{ language.data.choiceGoods }}</el-col>
                    <el-col :span="18" style="font-size: 12px;">{{productInfo.tproducts.productsName}}</el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row>
                    <el-col :span="6">{{ language.data.goodsOption }}</el-col>
                    <el-col :span="18" style="font-size: 12px;">{{selectFistProductListAndFirstOptionItemData.firstProductList.listName}}</el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row>
                    <el-col :span="6">{{ language.data.area }}</el-col>
                    <el-col :span="18" style="font-size: 12px;">{{centerDialogVisible.selectCountry.countryName}}
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row>
                    <el-col :span="6">{{ language.data.price }}</el-col>
                    <el-col :span="18" style="font-size: 14px;color: red;">
                      <span>{{showPrice.icon}}</span>
                      <span v-if="userFrom.addressSign==='东马' "> {{ showPrice.listPrice + 30}}</span>
                      <span v-if="userFrom.addressSign==='台湾+300' "> {{ showPrice.listPrice + 300}}</span>
                      <span v-if="userFrom.addressSign!=='东马' && userFrom.addressSign!=='台湾+300' "> {{ showPrice.listPrice }}</span>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row>
                    <el-col :span="6">{{ language.data.pay }}</el-col>
                    <el-col :span="18" style="font-size: 12px;">
                        Cash on Delivery/货到付款
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row>
                    <el-col :span="6">{{ language.data.name }}</el-col>
                    <el-col :span="18" style="font-size: 12px;">{{userFrom.name}}</el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row>
                    <el-col :span="6">{{ language.data.phone }}</el-col>
                    <el-col :span="18" style="font-size: 12px;">{{userFrom.phone}}</el-col>
                </el-row>
                <span v-if="centerDialogVisible.selectCountry.id != '1658133405726007298' && centerDialogVisible.selectCountry.id != '1658133367197130754'">
                    <el-divider></el-divider>
                    <el-row>
                        <el-col :span="6">{{ language.data.zipCode }}</el-col>
                        <el-col :span="18" style="font-size: 12px;">{{userFrom.zipCode}}</el-col>
                    </el-row>
                </span>

                <el-divider></el-divider>
                <el-row>
                    <el-col :span="6">{{ language.data.address }}</el-col>
                    <el-col :span="18" style="font-size: 12px;">{{userFrom.select1.selectName}}-{{userFrom.select2.selectName}}-{{userFrom.select3.selectName}}-{{userFrom.address}}</el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row>
                    <el-col :span="6">{{ language.data.email }}</el-col>
                    <el-col :span="18" style="font-size: 12px;">{{userFrom.email}}</el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row>
                    <el-col :span="6">{{ language.data.comment }}</el-col>
                    <el-col :span="18" style="font-size: 12px;">{{userFrom.comment}}</el-col>
                </el-row>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="danger" style="width: 100%;height: 50px;"
                           @click="submitOrderAction()">{{ language.data.sureOrder }}
                </el-button> <br><br>
                <el-button style="width: 100%;height: 50px;" @click="centerDialogVisible.checkOrderInfo.open = false">{{ language.data.backEditOrder }}</el-button>

            </span>
        </el-dialog>

        <!--订单结果-->
        <el-dialog :title="language.data.orderResult" class="pageBox" :showClose="false"
                   :visible.sync="centerDialogVisible.submitResult.open" width="90%" center>
            <div class="dialog-content">
                <el-result v-if="centerDialogVisible.submitResult.result" icon="success" :title="language.data.orderSubmitResult" subTitle="">
                    <template slot="extra">
                        <div style="text-align: left;padding:0px 15px;">
                            <b>{{ language.data.orderNumber }}：</b><span style="color: #F56C6C;">{{centerDialogVisible.submitResult.data.orderNumber}}</span>
                        </div>
                        <div style="text-align: left;padding:0px 15px;">
                            <b>{{ language.data.price }}：</b><span style="color: #F56C6C;"><span
                                style="margin-right: 3px;">{{showPrice.icon}}</span>{{centerDialogVisible.submitResult.data.price}}</span>
                        </div>
                        <br>
                        <div style="text-align: left; padding:0px 15px;">
                           {{language.data.successTip}}
                            <a href="#" @click="leftNavAction()"
                               style="font-weight: bolder;text-align:center;color: dodgerblue;text-decoration: dodgerblue;">{{ language.data.chat }}</a>。
                        </div>
                        <br>
                        <el-button type="success" size="medium" style="width: 100%;height: 50px; "
                                   @click="()=>{
                                    centerDialogVisible.submitResult.open = false;
                                    backAction();endOrderAction(centerDialogVisible.submitResult.data.price);}"
                        >
                          {{ language.data.ok }}
                        </el-button>
                    </template>
                </el-result>

            </div>

        </el-dialog>

        <!--选择地区-->
        <el-dialog :title="'选择地区'" class="pageBox"
                   :showClose="false"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
                   :visible.sync="centerDialogVisible.selectCountry.open" width="90%" center
        >
            <div class="dialog-content countryChoseBox">
                <span v-for="(k,v) in productInfo.salesCountryJSON"> <!--k 是值，v 是id-->
                    <el-card class="countryChoseBoxCar" :body-style="{ padding: '0px' }">
                        <el-image
                                :src="k.flag"
                                fit="cover" class="image"
                                style="width: 100px;height: 60px;"
                                :lazy="true"
                                @click="selectCountryAction(v)"
                        ></el-image>
                        <div style="padding: 8px 5px; font-size: 12px;text-align: center; font-weight: bolder;"
                             @click="selectCountryAction(v)">
                            <span>{{k.name}}</span>
                        </div>
                    </el-card>
                </span>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import Footer from "@/components/Footer"
    import FooterTip from "@/components/FooterTip"
    import {
        selectCountry,
        talkToEmployee,
        clickMyCar,
        checkThisOrder,
        endOrder
    } from "@/api/MetaPixelUtil"
    import {
        submitOrder,
        index,
    } from "@/api/api2"
    import axios from "axios";

    export default {
        name: "Index2",
        components: {Footer, FooterTip},
        data() {
            return {
                url:'',
                language: {
                  data:{
                    tips: "溫馨提示",
                    chat: "客服",
                    back: "返回",
                    car: "購物車",
                    choice: "選項",
                    inputTip: "請輸入",
                    priceTotal: "總價",
                    number: "數量",
                    pay: "支付方式",
                    name: "名字",
                    phone: "電話",
                    email: "郵箱",
                    zipCode: "郵編",
                    address: "地址",
                    choiceAddress: "請選擇地區",
                    addressTip: "請務必填寫完整的收貨地址，市，區，路，號，郵編.",
                    comment: "留言",
                    submitOrder:"提交訂單",
                    orderCheckTitle:"訂單確認信息",
                    choiceGoods:"選定產品",
                    goodsOption:"產選項品",
                    area:"地區",
                    price:"金額",
                    sureOrder:"確認訂單",
                    backEditOrder:"返回修改訂單",
                    orderResult:"訂單確認結果",
                    orderSubmitResult:"訂單提交結果",
                    orderNumber:"訂單編號",
                    successTip:"恭喜您,訂單提交成功.我們已經收到您的訂單，將盡快為您發寄出寶貝請您保持手機通暢，方便快遞員及時與您取得聯系， 如有任何疑問，請及時聯系我們 在線客服。祝您購物愉快!",
                    ok:"確定",
                    flags: [
                      {
                        name: "Free Shipping/免運費",
                        color: "#FFFFFF",
                        gbColor: "#FF0000",
                        use: true
                      },
                      {
                        name: "Cash on Delivery/貨到付款",
                        color: "#FFFFFF",
                        gbColor: "#FF0000",
                        use: true
                      },
                      {
                        name: "15天內無理由退換貨",
                        color: "#FFFFFF",
                        gbColor: "#000000",
                        use: true
                      },
                      {
                        name: "買一送一",
                        color: "#FFFFFF",
                        gbColor: "#FF0000",
                        use: false
                      },
                      {
                        name: "限時促銷",
                        color: "#FFFFFF",
                        gbColor: "#FF0000",
                        use: false
                      },
                      {
                        name: "店鋪直銷",
                        color: "#FFFFFF",
                        gbColor: "#FF0000",
                        use: false
                      },
                      {
                        name: "清倉處理",
                        color: "#FFFFFF",
                        gbColor: "#FF0000",
                        use: false
                      }
                    ],
                    userNote:[
                      {
                        title: "用戶須知",
                        content: "本產品的實際使用效果根據個人情況決定，不保證每位用戶都能享受到所宣傳的效果。若有疑問請咨詢在線客服聯絡我們，本公司享有最終解釋權。"
                      },
                      {
                        title: "如何申請退換貨",
                        content: "<p>1.由於個人原因產生的退換貨：自收到商品之日起15天內，在不影響二次銷售的情況下請聯系我們的在線客服，客服會在收到消息後的1-3個工作日內受理您的請求，退換貨所產生的運費需自行承擔。</p><p>2. 由於質量原因產生的退換貨：自收到商品之日起15天內請聯系我們的在線客服，客服會在收到郵件後的1-3個工作日內受理您的請求，退換貨所產生的運費由我方承擔。</p><p> 請註意，為了確保順利處理退換貨請求，請在聯系客服時提供訂單號、姓名和電話，並詳細說明退換貨的原因。我們會盡快處理您的請求，以確保您的權益得到保障。</p>"
                      },
                      {
                        title: "退貨流程",
                        content: "確認收貨—申請退換貨—客服審核通過—用戶寄回商品—倉庫簽收驗貨—退換貨審核—退款/換貨。退換貨請註明：訂單號、姓名、電話。"
                      },
                      {
                        title: "聯系信息",
                        content: "<p>我們的在線客服服務時間：（UTC/GMT + 08:00）9:00-18:00。我們會在1個工作日內（24小時內）給您回復。</p><p>溫馨提醒：為更有效率地解決問題，請在聯系客服團隊之前提前準備好訂單編號等信息哦！</p>"
                      },
                      {
                        title: "隱私政策",
                        content: "隱私聲明:我們非常重視客戶的隱私。因此，我們只會根據本隱私聲明所規定的方式收集和使用您的個人資料。1. 我們僅在有必要或與交易相關的情況下收集您的資料。2. 我們僅在法律要求或與收集目的相關的情況下保留您的資料。在您未登錄用戶賬號瀏覽我們的網站時，您將以匿名身份進行瀏覽，而我們無法識別您的身份。我們非常歡迎您提供寶貴的意見和建議。如果您有任何意見或建議，請聯系客服。"
                      }
                    ]
                  }
                },
                maxHeight:0,
                productInfo: {tproducts: {}, salesCountryJSON: {}, topFiles: []},
                selectFistProductListAndFirstOptionItemData: {firstProductList:{}},
                itemData:{
                    data:[],
                    title:[],
                    pos:[]
                },
                userFrom: {selectAddress:{},addressPrefix:'',
                  select1:{data:[],select:'',selectName:''},
                  select2:{data:[],select:'',selectName:''},
                  select3:{data:[],select:'',selectName:''},
                  select4:{data:[],select:'',selectName:''}
                },
                centerDialogVisible: {
                    checkOrderInfo: {
                        open: false
                    },
                    submitResult: {
                        open: false,
                        result: false,
                        data: {
                            orderNumber: '',
                            price: 0
                        }
                    },
                    selectCountry: {
                        open: true,
                        id: '',
                        countryName: ''
                    }
                },
                country: [],
                countryIcon: {},
                showPrice: {
                    lowerPrice: 0,
                    icon: 'RMB',
                    listPrice: 0,
                },
                productId: "",
                productHost: "",
                product: {},
                topImages: [],
                detailImages: [],
                productsFlag: [],
                webAddress: [],
                productList: [],
                buyOptions: [],
                salesCountry: [],
                openCar: {
                    open: false
                },
                selectData: {
                    productList: {},
                    optionItem: {}
                },
                countryFlags: {
                    data: [],
                    value: ""
                },
                webAppInitData: {
                    appid: "25918206287770288",
                    version: 'V19.0'
                },
            }
        },
        created() {},
        async mounted() {

            this.productId = this.$route.query.id
            this.productHost = this.$route.query.productHost
            console.log("url参数：", this.productId, this.productHost)

            console.log("this.$route.fullPath:", this.$route.fullPath)

            var paths = this.$route.fullPath.split("/").filter(element => {
                return element !== '' && element !== undefined && element !== null && element.trim() !== '';
            })

            const protocol = window.location.protocol;
            const hostname = window.location.hostname;
            const port = window.location.port;

            this.url=protocol+"//"+hostname+":"+port

            this.productHost = paths[0]
            console.log("productHost:", paths[0],protocol,hostname,port)
            if (paths[0]==undefined || paths[0] == null){
                this.productHost = "Inexcusable"
            }

            this.indexAction()
        },
        methods: {
            // 计算图片的最大高度
            getMaxHeight(){
                /*var images = this.productInfo.topFiles
                for (let i = 0; i < images.length; i++) {
                    console.log("图片",images[i].filePath)
                    getOriginalImageSize(images[i].filePath).then(size => {
                        console.log('图片的原始宽度：',size);
                        if (size.renderedHeight > this.maxHeight){
                            this.maxHeight = size.renderedHeight
                        }
                    })
                    .catch(error => {
                        console.error('发生错误：', error);
                    });
                }*/
            },
            handleImageLoad(){
                const imageElement = event.target;
                //console.log('renderedWidth:', imageElement.width, 'renderedHeight:', imageElement.height);
                if (imageElement.height > this.maxHeight){
                    this.maxHeight = imageElement.height
                }
                /*getOriginalImageSize(url).then(size => {
                    console.log('图片的原始宽度：',size);
                    if (size.renderedHeight > this.maxHeight){
                        this.maxHeight = size.renderedHeight
                    }
                })
                .catch(error => {
                    console.error('发生错误：', error);
                });*/
            },

            // 统一获取 - 像素事件
            indexAction() {
                var param = {hostsName: this.productHost}
                index(param).then(res => {

                    console.log("统一获取", res.data)
                    this.productInfo = res.data;
                    // 改变浏览器窗口名称
                    document.title=res.data.tproducts.productsName
                    this.getMaxHeight()

                }, error => this.$message.error(error))
                .then(res => {
                    this.metaAction()
                }, error => this.$message.error(error))
                .then(res => {
                    this.countMinPriceByProductListAction()
                }, error => this.$message.error(error))
                .then(res => {
                  this.oneCountry()
                }, error => this.$message.error(error))
            },

            // 单个国家不用选择
            oneCountry(){
              if(Object.keys(this.productInfo.salesCountryJSON).length<=1){
                this.centerDialogVisible.selectCountry.open=false
                this.selectCountryAction(Object.keys(this.productInfo.salesCountryJSON)[0])
              }
            },

            // 计算所选择的国家对应的产品列表的最低价格
            countMinPriceByProductListAction() {
                // 获取产品列表信息
                for (const key in this.productInfo.salesCountryJSON) {
                    var value = this.productInfo.salesCountryJSON[key]
                    for (const key2 in value.productListItem) {
                        // console.log(this.showPrice.lowerPrice, value.productListItem[key2].price,
                        //     this.showPrice.lowerPrice > value.productListItem[key2].price,
                        //     this.showPrice.lowerPrice > value.productListItem[key2].productCompositionPrice)

                        if (this.showPrice.lowerPrice > value.productListItem[key2].price) {
                            this.showPrice.lowerPrice = value.productListItem[key2].price
                        } else if (this.showPrice.lowerPrice > value.productListItem[key2].productCompositionPrice) {
                            this.showPrice.lowerPrice = value.productListItem[key2].productCompositionPrice
                        } else {
                            this.showPrice.lowerPrice = value.productListItem[key2].price < value.productListItem[key2].productCompositionPrice ? value.productListItem[key2].price : value.productListItem[key2].productCompositionPrice;
                        }
                        this.showPrice.icon = value.currency_icon
                    }
                }
                //console.log("this.showPrice", this.showPrice)
            },

            // 联系客服 - 像素事件
            leftNavAction() {
                // 调用像素
                talkToEmployee({
                    content_category: '按钮-联系客服',
                    content_type: 'product',
                    contents: [{
                        action_name: '联系客服',
                        id: this.productInfo.tproducts.id,
                        quantity: 1,
                        hostsName: this.productInfo.tproducts.hostsName
                    }],
                    currency: "USD",
                    num_items: 1,
                    search_string: '',
                    status: true,
                    value: 0,
                    predicted_ltv: 0,
                    content_name: this.productInfo.tproducts.hostsName,
                    content_ids: this.productInfo.tproducts.id
                });
                window.open(this.product.facebookLink)
            },

            // 购物车 - 像素事件
            rightNavAction() {
                // 调用像素
                clickMyCar({
                    content_category: '按钮-点击购物车',
                    content_type: 'product',
                    contents: [{
                        action_name: '点击购物车',
                        id: this.productInfo.tproducts.id,
                        quantity: 1,
                        hostsName: this.productInfo.tproducts.hostsName
                    }],
                    currency: "USD",
                    num_items: 1,
                    search_string: '',
                    status: true,
                    value: 0,
                    predicted_ltv: 0,
                    content_name: this.productInfo.tproducts.hostsName,
                    content_ids: this.productInfo.tproducts.id
                })
                this.openCar.open = true
            },

            // 返回
            backAction() {
                this.openCar.open = false
            },

            // 选择国家 - 像素事件
            selectCountryAction(i) {
                let language = this.productInfo.salesCountryJSON[i].page_language_set
                if (language) {
                  this.language.data = language
                }
                console.log("language",this.language,language)

                this.centerDialogVisible.selectCountry.id = i;
                this.centerDialogVisible.selectCountry.countryName = this.productInfo.salesCountryJSON[i].name
                this.centerDialogVisible.selectCountry.open = false;
                this.showPrice.icon = this.productInfo.salesCountryJSON[i].currency_icon

                // 调用像素
                selectCountry({
                    content_category: '弹窗-选择销售国家',
                    content_type: 'product',
                    contents: [{
                        action_name: '选择销售国家',
                        id: this.productInfo.tproducts.id,
                        quantity: 1,
                        hostsName: this.productInfo.tproducts.hostsName
                    }],
                    currency: "USD",
                    num_items: 1,
                    search_string: '',
                    status: true,
                    value: 0,
                    predicted_ltv: 0,
                    content_name: this.productInfo.tproducts.hostsName,
                    content_ids: this.productInfo.tproducts.id
                });

                this.selectFistProductListAndFirstOptionItem()
                this.initOptionItem()

                this.countryMixPrice()

                this.initChangeAddress()
            },

            // 计算选择国家中最小的套餐价格
            countryMixPrice(){
                var data = this.productInfo.salesCountryJSON[this.centerDialogVisible.selectCountry.id].productListItem
                //console.log("dianji ",data,data.length)
                var mix = 0;
                Object.keys(data).forEach(function(key) {
                    //console.log(mix , data[key].price)
                    if(mix==0){
                        mix = data[key].price
                    } else if (mix > data[key].price ){
                        mix = data[key].price
                    }
                });
                this.showPrice.lowerPrice = mix;
                this.showPrice.icon = this.productInfo.salesCountryJSON[this.centerDialogVisible.selectCountry.id].currency_icon
            },

            // 初始化选项。默认选择第一个选项，以三位数组矩阵表示选中值
            initOptionItem(productList){
                // 当前选择到的列表
                var keys =null;
                var firstkey =null;
                var firstEleObj =null;
                if(productList){
                    firstEleObj = productList
                    // console.log("点击",productList)
                }else{
                    keys = Object.keys(this.productInfo.salesCountryJSON[this.centerDialogVisible.selectCountry.id].productListItem)
                    if(keys.length<=0){
                      return false;
                    }
                    firstkey = keys[0]
                    firstEleObj = this.productInfo.salesCountryJSON[this.centerDialogVisible.selectCountry.id].productListItem[firstkey]
                    firstEleObj['id'] = firstkey
                    // console.log("初始化",this.productInfo.salesCountryJSON[this.centerDialogVisible.selectCountry.id].productListItem)
                }

                const title1 = new Array();
                const data1 = new Array();
                const show1 = new Array();
                const temp1 = new Array(); // 第一层
                for(var i=0;i<firstEleObj.total; i++) {
                    const temp2 = new Array();// 第二层
                    const show2 = new Array();
                    const data2 = new Array();
                    const title2 = new Array();

                    var keys2 = Object.keys(firstEleObj.optionList)
                    for (var j=0;j<keys2.length;j++){
                        var optionItemList = firstEleObj.optionList[keys2[j]].optionList
                        const temp3 = new Array();// 第三层
                        const show3 = new Array();
                        const data3 = new Array();

                        for (var h = 0;h<optionItemList.length;h++){
                            temp3.push(optionItemList[h].id)
                            data3.push(optionItemList[h])
                            if(h == 0){
                                show3.push(1)
                            }else{
                                show3.push(0)
                            }
                        }
                        temp2.push(temp3)
                        show2.push(show3)
                        data2.push(data3)
                        title2.push(firstEleObj.optionList[keys2[j]].optionName)
                    }
                    temp1.push(temp2);
                    show1.push(show2);
                    data1.push(data2)
                    title1.push(title2)
                }
                console.log("firstEleObj new ",temp1,show1)
                console.log("firstEleObj new data1",data1,title1)
                this.itemData.data=data1;
                this.itemData.pos=show1
                this.itemData.title=title1
            },

            // 默认选择第一个购买列表
            selectFistProductListAndFirstOptionItem() {
                var keys = Object.keys(this.productInfo.salesCountryJSON[this.centerDialogVisible.selectCountry.id].productListItem)
                if(keys.length<=0){
                  return false;
                }
                console.log("keys",keys)
                var firstkey = keys[0]
                var firstEleObj = this.productInfo.salesCountryJSON[this.centerDialogVisible.selectCountry.id].productListItem[firstkey]
                firstEleObj['id'] = firstkey
                this.selectFistProductListAndFirstOptionItemData.firstProductList = firstEleObj
                this.showPrice.listPrice = firstEleObj.price
                this.userFrom.total = firstEleObj.total
                console.log("默认选择第一个购买列表",firstEleObj)
            },

            // 点击购买列表
            productListClickAction(k,id){
                const productList = k
                productList['id'] = id
                this.selectFistProductListAndFirstOptionItemData.firstProductList['id']= productList.id
                this.selectFistProductListAndFirstOptionItemData.firstProductList = productList

                this.showPrice.listPrice = productList.price
                this.userFrom.total = productList.total

                console.log("点击购买列表：",productList,this.selectFistProductListAndFirstOptionItemData)

                this.initOptionItem(productList)
            },

            // 点击购买选项
            optionItemClick(v,v2,index){
                console.log("点击购买选项：",v,v2,index,this.itemData.pos[v][v2][index])
                for (var i =0 ;i<this.itemData.pos[v][v2].length;i++){
                    this.$set(this.itemData.pos[v][v2], i, 0); // this.itemData.pos[v][v2][i]=0
                }
                this.$set(this.itemData.pos[v][v2], index, 1);//this.itemData.pos[v][v2][index]=1
                //console.log("点击购买选项改变坐标值：",this.itemData.pos)
                //this.optionItemValue()
            },

            // 选择的购买选项数值
            optionItemValue(){
                var temp=[];
                for (var i =0 ;i<this.itemData.pos.length;i++){
                    let item1 = this.itemData.pos[i]
                    var temp1=[];
                    for (let j = 0; j < item1.length; j++) {
                        let item2 = this.itemData.pos[i][j]
                        var temp2=[];
                        for (let k = 0; k < item2.length; k++) {
                            let item3 = this.itemData.pos[i][j][k] // 选中状态的值
                            if(item3==1){
                                temp2.push(this.itemData.data[i][j][k])
                            }
                        }
                        temp1.push(temp2);
                    }
                    temp.push(temp1)
                }
                // console.log("选择的购买选项数值：",this.itemData.pos, this.itemData.data,temp)
                return temp;
            },

            //点击 Place Order/提交订单  - 像素事件
            checkOrder(){
                // 校验是否选择列表
                if (this.userFrom.total == undefined || this.userFrom.total == null) {
                    this.$message.error("请选择购买选项")
                    return false;
                }

                this.$refs['$form'].validate((valid) => {
                    if (!valid) {
                        this.$message.error("请填写完整信息")
                        return false;
                    } else {
                        // 判断邮编是否已经填写,未填写且不是香港和台湾的则提示
                        if (
                            (this.userFrom.zipCode == null || this.userFrom.zipCode.length<=0||this.userFrom.zipCode === '' )
                            && (
                                this.centerDialogVisible.selectCountry.id !== '1658133405726007298' && this.centerDialogVisible.selectCountry.id !== '1658133367197130754'
                            )
                        ) {
                          this.$message.error("请填写邮编")
                          return false;
                        }
                        if(this.userFrom.name  == null || this.userFrom.name.length<=0||this.userFrom.name === '' ){
                          this.$message.error("请填写")
                          return false;
                        }
                        if(this.userFrom.phone  == null || this.userFrom.phone.length<=0||this.userFrom.phone === '' ){
                          this.$message.error("请填写邮编")
                          return false;
                        }
                        this.centerDialogVisible.checkOrderInfo.open = true
                    }
                });
            },

            // 点击确认订单 按钮 - 像素事件
            submitOrderAction(){
                this.centerDialogVisible.checkOrderInfo.open = false;

                var param = {
                    goodsId: this.productInfo.tproducts.id,
                    recipient: this.userFrom.name,
                    country: this.centerDialogVisible.selectCountry.id,
                    province: this.userFrom.province, // 地区/省份
                    city: this.userFrom.city,
                    stateName: this.userFrom.state,
                    shippingAddress: this.userFrom.select1.selectName+'-'+this.userFrom.select2.selectName+'-'+this.userFrom.select3.selectName+'-'+this.userFrom.address,
                    houseNumber: this.userFrom.houseNumber,//
                    zipCode: this.userFrom.zipCode,
                    receivingPhoneNumber: this.userFrom.phone,
                    collectionOnDelivery: this.showPrice.listPrice,
                    paymentMethod: this.userFrom.paymentMethod,
                    email: this.userFrom.email,
                    remark: this.userFrom.comment,
                    orderHost:this.url,
                    listId: this.selectFistProductListAndFirstOptionItemData.firstProductList.id,
                    optionItem: JSON.stringify(this.optionItemValue())
                }
                console.log("提交订单", param)

                submitOrder(param).then(res => {
                    console.log("订单", res)
                    this.centerDialogVisible.submitResult.data.orderNumber = res.data.orderNumber;
                    this.centerDialogVisible.submitResult.data.price = res.data.price;
                    this.centerDialogVisible.submitResult.result = true;
                    this.centerDialogVisible.submitResult.open = true;

                    // 调用像素
                    checkThisOrder({
                        content_category: '按钮-点击确认订单',
                        content_type: 'product',
                        contents: [{action_name: '点击确认订单', id: this.productInfo.tproducts.id, quantity: 1, hostsName: this.productInfo.tproducts.hostsName}],
                        currency: "USD",
                        num_items: 1,
                        search_string: '',
                        status: true,
                        value: 0,
                        predicted_ltv: 0,
                        content_name: this.productInfo.tproducts.hostsName,
                        content_ids: this.productInfo.tproducts.id
                    })

                }, error => this.$message.error(error))
            },

            // 订单结果确认按钮 - 像素事件
            endOrderAction(price){
                // endOrder({value:price,currency:'USD'});
                endOrder({
                    content_category: '弹窗-点击订单确认结果',
                    content_type: 'product',
                    contents: [{action_name: '点击订单确认结果', id: this.productInfo.tproducts.id, quantity: 1, hostsName: this.productInfo.tproducts.hostsName}],
                    currency: "USD",
                    num_items: 1,
                    search_string: '',
                    status: true,
                    value:price,
                    predicted_ltv: 0,
                    content_name: this.productInfo.tproducts.hostsName,
                    content_ids: this.productInfo.tproducts.id
                });
            },

            // 改变像素
            metaAction() {
                !function (f, b, e, v, n, t, s) {
                    if (f.fbq) return;
                    n = f.fbq = function () {
                        n.callMethod ?
                            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                    };
                    if (!f._fbq) f._fbq = n;
                    n.push = n;
                    n.loaded = !0;
                    n.version = '2.0';
                    n.queue = [];
                    t = b.createElement(e);
                    t.async = !0;
                    t.src = v;
                    s = b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t, s)
                }(window, document, 'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', this.productInfo.tproducts.facebookMessagerId);
                fbq('track', 'PageView');
                fbq('track', 'ViewContent');
            },


            // 初始化地址选择
            initChangeAddress(){
                return false;

                if(this.productInfo.salesCountryJSON[this.centerDialogVisible.selectCountry.id].treeNode.length<=0){
                  return false
                }
                let s1 = this.productInfo.salesCountryJSON[this.centerDialogVisible.selectCountry.id].treeNode[0].id || ''
                this.userFrom.select1.select = s1
                this.changeAddress1(s1)

                let s1c = this.productInfo.salesCountryJSON[this.centerDialogVisible.selectCountry.id].treeNode[0].children || []
                if(s1c.length<=0){
                  return false
                }

                let s2 = s1c[0].id
                this.userFrom.select2.select = s2
                this.changeAddress2(s2)

                let tem3 = s1c[0].children || []
                if(tem3.length<=0){
                  return false
                }
                this.userFrom.select3.select = tem3[0].id
            },
            // 地址选择
            changeAddress1(data){
                let selectValue = this.productInfo.salesCountryJSON[this.centerDialogVisible.selectCountry.id].treeNode.filter(item=>item.id===data)
                console.log("changeAddress1",data,selectValue,selectValue[0].remark)
                this.userFrom.select1.data = selectValue[0].children
                this.userFrom.select1.selectName = selectValue[0].dictValue

                if(selectValue[0].remark.includes('东马')){
                  this.userFrom.addressSign ='东马'
                }else if(["1789213982335258626","1789213740948869121","1789213549717966850"].includes(selectValue[0].id)){
                  this.userFrom.addressSign ='台湾+300'
                }else{
                  this.userFrom.addressSign =''
                }
            },
            changeAddress2(data){
              let selectValue = this.userFrom.select1.data.filter(item=>item.id==data)
              console.log("changeAddress2",data,selectValue)
              this.userFrom.select2.data = selectValue[0].children
              this.userFrom.select2.selectName = selectValue[0].dictValue
              if(selectValue[0].remark.includes('东马')){
                this.userFrom.addressSign ='东马'
              }
            },
            changeAddress3(data){
              let selectValue = this.userFrom.select2.data.filter(item=>item.id==data)
              console.log("changeAddress3",data,selectValue)
              this.userFrom.select3.data = selectValue[0].children
              this.userFrom.select3.selectName = selectValue[0].dictValue
              if(selectValue[0].remark.includes('东马')){
                this.userFrom.addressSign ='东马'
              }
            }

        }
    }
</script>

<style scoped>

    * {
        padding: 0px;
        margin: 0px;
    }

    /deep/ .el-input__inner::placeholder,
    /deep/ .el-textarea__inner::placeholder{
      color: rgb(123,123,123); /* 修改为红色 */
      opacity: 1; /* 保持不透明度，确保颜色可见 */
      font-weight: bolder;
    }

    /deep/ .el-form-item{
      margin-bottom: 0px;
    }

    /deep/ .el-form-item__label {
      width: 100px;
      text-align:left;
      font-family: 'SimHei';
      font-weight: bolder;
      padding-left:15px;
    }

    /deep/ .el-input__inner,
    /deep/ .el-textarea__inner {
      border: none !important;
      box-shadow: none !important;
    }

    /deep/ div div.el-dialog--center div.el-dialog__body {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
    }

    .countryChoseBoxCar {
        width: 100px;
        height: 100px;
        display: inline-block;
        margin: 5px;
    }


    .footerBox {
        background: red;
        position: fixed;
        left: auto;
        bottom: 0px;
        width: 100vw;
        height: 50px;
        line-height: 50px;
        text-align: center;
    }

    .footerBox span {
        display: block;
    }

    .rightNavBox i, .leftNavBox i {
        font-size: 24px;
    }

    .rightNavBox {
        color: white;
    }

    .leftNavBox {
        background: white;
    }

    .el-form-item label {
        font-size: 12px;
    }

    .topImgItem {
        height: 640px;
        width: 640px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

    }

    .productsName,
    .details,
    .productsFlag {
        padding: 5px;
    }

    .toTop {
        height: 40px;
        width: 40px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 6px rgba(0, 0, 0, .12);
        text-align: center;
        line-height: 40px;
        color: #FFF;
        position: fixed;
        right: 0;
        bottom: 60px;
        border-radius: 50px;
        border: 1px solid gray;
    }

    .backBox {
        padding: 10px 0px 10px 10px ;
        text-align: center;
        box-shadow: gray 0 1px 5px;
        position: fixed;
        /*position: relative;*/
        top: 0;
        left: auto;
        width: 630px;
        background: #FFFFFF;
        z-index: 999;
        margin: auto;
    }
    .el-carousel__item img {
        max-width: 100%; /* 设置图片最大宽度 */
        max-height: 100%; /* 设置图片最大高度 */
    }

    .BuyFormBox {
        padding: 10px 5px;
        margin-top: 45px;
    }

    .BuyFormBox-productsName {
        font-size: 14px;
        padding: 0px 5px 0px 5px;
    }

    .BuyFormBox-details {
        font-size: 14px;
        padding: 5px 5px 0px 5px;
    }

    .BuyFormBox-presentPrice {
        font-size: 14px;
        color: red;
    }

    .BuyFormBox-productList {
        font-size: 12px;
        border: 1px solid #DCDFE6;
        padding: 10px 5px;
        margin: 15px 0px 0px 0px;
    }

    .BuyFormBox-productList-click {
        font-size: 12px;
        border: 1px solid #DCDFE6;
        padding: 10px 5px;
        margin: 15px 0px 0px 0px;
        background: #F56C6C;
        color: #FFFFFF;
    }

    .BuyFormBox-optionItem ,
    .optionItem{
        border: 1px solid #DCDFE6;
        padding: 5px 15px;
        margin: 5px 2px;
        display: inline-block;
    }

    .BuyFormBox-optionItem-click,
    .optionItem-click{
        background: #F56C6C;
        color: #FFFFFF;
    }

    .el-divider--horizontal {
        margin: 8px 0px !important;
    }

    .list-price{
        position: absolute;
        top:-25px;
        left:calc(50% - 75px);
        background: rgba(103,194,58,1);
        color: #FFFFFF;
        padding:2px 15px;
        border-radius: 15px;

        text-align: center;
    }

    .list-price-click{
        position: absolute;
        top:-25px;
        left:calc(50% - 75px);
        background: #F56C6C;
        color: #FFFFFF;
        padding:2px 15px;
        border-radius: 15px;

        text-align: center;
    }

    .pageBox-main-box{
        border:1px solid gray;
    }

    /* 当宽度大于960px时，调整样式 */
    @media screen and (min-width: 961px) {
        .pageBox {
            max-width: 640px; /* 当宽度大于960px时，最大宽度调整为640px */
            margin: auto;
        }
    }





</style>
