<template>
    <div>
        <div class="footerTipBox">
            到底了
        </div>
    </div>
</template>

<script>
    export default {
        name: "FooterTip",
        data() {
            return {}
        },
        methods: {}
    }
</script>

<style scoped>
    .footerTipBox{
        color: gray;
        font-size: 14px;
        height: 50px;
        line-height: 50px;
        text-align: center;
    }
</style>
