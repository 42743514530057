/**
 * 安全处理工具，RSA DES MD5
 */
import JSEncrypt from 'jsencrypt'
import { MD5 } from 'crypto-js'

const publicKey = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjIV+Gjc7s5AuylrFd5Y460H/vA9X1o+EBX9PFUu7ueDvfeKxBrJc+NyYlg3D5+uZbMYSkSvLgH3QQjKEpGMpQhoIan/mBYqLtuEQC7X3gkutsrzdmNHYLhfULfFnjjDLzZqPum0NzSQsmMXNXtdnjzKYTv805DPV3vTMKjQp9jDeRl8tyMFKRBBS+pg5A8fkjeCTBQfvaQn2LVDUgD5GSjOJafz+IjBR8q/2Xb0UN9IaTFmnbEl9UUE8fBMTyoqW8brZ5Fa0PJ3slyCV/NqZqGlGvecDqd1fy2tXUguid7jdLW41Da4kFLxr4vI3IpiiE3xeST8xKWqdjtndf/53xQIDAQAB";

/**
 * RSA
 * */
export function RSAEncryption(data) {
  var encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey)
  return encryptor.encrypt(data)
}
export function RSADecryption(data) {

}


/**
 * DES
 * */
export function DESEncryption() {

}
export function DESDecryption() {

}


/**
 * MD5
 * */
export function MD5Encryption(data) {
  return  MD5(data).toString()
}
export function MD5DoubleEncryption(data) {
  return  MD5Encryption(MD5Encryption(data))
}
