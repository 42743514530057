import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/api/auth'
import { RSAEncryption,MD5DoubleEncryption } from '@/api/securityUtil'

// create an axios instance
const service = axios.create({
  // baseURL: "http://127.0.0.1:8080/", // url = base url + request url
  // baseURL: "http://192.168.83.143:8080/", // url = base url + request url
  baseURL: "https://www.monroegroup.shop/QkmYmxApi", // url = base url + request url
  // baseURL: "https://www.themonroe.shop/QkmYmxApi", // url = base url + request url
  // baseURL: "https://www.themonroe.shop/QkmYmxApi", // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    //console.log("config",config.url)
    if(config.url== "/attachment/upload"){
     // 文件上传，修改请求头
      config.headers['Content-Type'] = "multipart/form-data"
    }else{
      config.headers['Content-Type'] = "application/json"
    }
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['token'] = getToken()
      config.headers['clientId'] = RSAEncryption('QKMHOME')
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        MessageBox.confirm('您已注销，您可以取消以留在此页面，也可以重新登录', '确认注销', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
