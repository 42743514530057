import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/Index.vue'
import Index2 from '../views/Index2.vue'
import BuyForm from '../views/BuyForm.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
 /* {
    path: '/',
    name: 'Home',
    component: Home
  },*/
  {
    path: '*',
    name: 'Index2',
    component: Index2
  },
  {
    path: '/buyForm',
    name: 'BuyForm',
    component: BuyForm
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
