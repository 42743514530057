<template>
    <div class="footerBox">
        <el-row>
            <el-col :span="6" class="leftNavBox">
               <span @click="leftNavAction()">
                    <i class="el-icon-chat-dot-round"></i>
               </span>
            </el-col>
            <el-col :span="18" class="rightNavBox">
                <span @click="rightNavAction()">
                    <i class="el-icon-shopping-cart-full"></i>
                    购物车
                </span>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "Footer",
        props: {
            leftNav: {
                type: Object
            },
            rightNav: {
                type: Object
            },
        },
        data() {
            return {}
        },
        methods: {
            leftNavAction() {
                console.log(this.leftNav.url.indexOf("http://"), this.leftNav.url.indexOf("https://"))
                if (this.leftNav.url.indexOf("http://") >= 0 || this.leftNav.url.indexOf("https://") >= 0) {
                    window.open(this.leftNav.url)
                } else {
                    this.$router.push({path: this.leftNav.url})
                }
            },
            rightNavAction() {
                this.$router.push({path: this.rightNav.url, query: {hostsName: this.rightNav.params.hostsName}})
            }
        }
    }
</script>

<style scoped>
    * {
        padding: 0px;
        margin: 0px;
    }

    .footerBox {
        background: red;
        position: fixed;
        left: 0px;
        bottom: 0px;
        width: 100vw;
        height: 50px;
        line-height: 50px;
        text-align: center;
    }

    .footerBox span {
        display: block;
    }

    .rightNavBox i, .leftNavBox i {
        font-size: 24px;
    }

    .rightNavBox {
        color: white;
    }

    .leftNavBox {
        background: white;
    }
</style>
