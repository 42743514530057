<template>
    <div>
        <div class="backBox">
            <el-page-header @back="back" content="购物车"></el-page-header>
        </div>
        <div class="BuyFormBox">
            <div>
                <el-row>
                    <el-col :span="6">
                        <el-image fit="cover" :src="topImages[0].filePath   "></el-image>
                    </el-col>
                    <el-col :span="14"></el-col>
                    <el-col :span="8"></el-col>
                </el-row>
            </div>
        </div>


    </div>
</template>

<script>
    import {getProducts} from "@/api/api";
    import {listNotPageProductsFlag} from '@/api/api'
    import {listAttachmentNotPage} from '@/api/api'

    export default {
        name: "BuyForm",
        data() {
            return {
                product: {},
                topImages: [],
                detailImages: [],
                productsFlag: [],
            }
        },
        mounted() {
            this.productId = this.$route.query.id
            this.productHost = this.$route.query.productHost
            console.log("url参数：", this.productId, this.productHost)
            var paths = this.$route.fullPath.split("/")
            console.log("url：", paths, paths[paths.length - 1])
            this.productHost = paths[paths.length - 1]
            //this.getProductInfoById()
        },
        methods: {
            back() {
                this.$router.go(-1)
            },
            //根据id获取产品信息
            getProductInfoById() {
                var param = {};
                param["hostsName"] = this.productHost
                //console.log("根据id获取产品信息id", param)
                getProducts(param).then(res => {
                    this.product = res.data
                    this.productId = res.data.id
                    this.footer.left.url = this.product.facebookLink
                    this.footer.right.params = this.product
                    console.log("根据id获取产品信息", this.product )

                    // 顶部图片
                    this.getTopFilesAction()
                    // 详情图片
                    this.getDetailFilesAction()
                    // 不分页查找商品标签
                    this.getListNotPageProductsFlag()

                }, error => {
                    this.$message.error(error)
                    //this.$router.push("products", null)
                })
            },
            // 查询顶部图片
            getTopFilesAction() {
                listAttachmentNotPage({
                    refId: this.productId,
                    fileCode: 'products_top_file',
                    refTable: 't_products'
                }).then(res => {
                    console.log("查询顶部图片", res)
                    this.topImages = []
                    res.data.forEach((item) => {
                        this.topImages.push({id: item.id, filePath: item.filePath})
                    })
                }, error => this.$message.error(error))
            },
            // 查询详情图片
            getDetailFilesAction() {
                listAttachmentNotPage({
                    refId: this.productId,
                    fileCode: 'products_detail_file',
                    refTable: 't_products'
                }).then(res => {
                    console.log(" 查询详情图片", res)
                    this.detailImages = []
                    res.data.forEach((item) => {
                        this.detailImages.push({id: item.id, filePath: item.filePath})
                    })
                }, error => this.$message.error(error))
            },
            // 不分页查找商品标签
            getListNotPageProductsFlag() {
                var param = {};
                param['productId'] = this.productId
                listNotPageProductsFlag(param).then(res => {
                    this.productsFlag = res.data
                }, error => {
                    this.$message.error(error);
                })
            },
        }
    }
</script>

<style scoped>
    .backBox {
        background: #FFFFFF;
        padding: 10px 10px;
        text-align: center;
        box-shadow: gray 0 1px 5px ;
    }
    .BuyFormBox{
        padding: 10px 5px;
    }
</style>
