import request from '@/api/request'
/**
 * 登录
 * @param data
 * @returns {AxiosPromise}
 */
export function login(data) {
    // console.log("data",data)
    return request({
        url: '/user/login',
        method: 'post',
        data:JSON.stringify(data)
    })
}
/**
 * 获取产品详情
 */
export function getProducts(data) {
    return request({
        url: '/products/get',
        method: 'post',
        data:JSON.stringify(data)
    })
}

/**
 * 附件
 * @param
 */
export function listAttachmentNotPage(data) {
    return request({
        url: '/attachment/listNotPage',
        method: 'post',
        data:JSON.stringify(data)
    })
}


/**
 * 产品标签不分页列表
 */
export function listNotPageProductsFlag(data) {
    return request({
        url: '/productsFlag/listNotPage',
        method: 'post',
        data:JSON.stringify(data)
    })
}

/**
 * 不分页列表
 */
export function listNotPageBuyOptions(data) {
    return request({
        url: '/buyOptions/listNotPage',
        method: 'post',
        data:JSON.stringify(data)
    })
}

/**
 * 不分页列表,返回所有数据
 */
export function listNotPageAllProductList(data) {
    return request({
        url: '/productList/listNotPageAll',
        method: 'post',
        data:JSON.stringify(data)
    })
}

/**
 * 根据产品列表、产品购买选项组的价格，获取最低价格返回，用于显示在页面上
 */
export function getLowerPriceByListCountry(data) {
    return request({
        url: '/productList/getLowerPriceByListCountry',
        method: 'post',
        data:JSON.stringify(data)
    })
}
/**
 * 查询指定列表的价格，如果选择了国家则按照国家的价格，未选择国家则按照列表价格
 */
export function getPriceByListCountry(data) {
    return request({
        url: '/productList/getPriceByListCountry',
        method: 'post',
        data:JSON.stringify(data)
    })
}

/**
 * 查询 购买选项子项详情
 */
export function getAllBuyOptions(data) {
    return request({
        url: '/buyOptions/get',
        method: 'post',
        data:JSON.stringify(data)
    })
}

/**
 * 不分页列表
 */
export function listNotPageSalesCountry(data) {
    return request({
        url: '/salesCountry/listNotPage',
        method: 'post',
        data:JSON.stringify(data)
    })
}

/**
 *  获取字典类型
 * @param
 * */
export function listSysDictNotPage(data) {
    return request({
        url: '/sysDict/listNotPage',
        method: 'post',
        data:JSON.stringify(data)
    })
}

export function listSysDictNotPageTree(data) {
    return request({
        url: '/sysDict/listNotPageTree',
        method: 'post',
        data:JSON.stringify(data)
    })
}



/**
 * 提交订单
 */
export function submitOrder(data) {
    return request({
        url: '/orders/add',
        method: 'post',
        data:JSON.stringify(data)
    })
}




/**
 * 测试数据
 */
export function index(data) {
    return request({
        url: '/index/productInfoIndex',
        method: 'post',
        data:JSON.stringify(data)
    })
}


export function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

/**
 * 计算图片宽高
 **/
export function getOriginalImageSize(imageUrl) {
    return new Promise((resolve, reject) => {
        // 创建一个 Image 对象
        var img = new Image();

        // 设置图片的 src 属性
        img.src = imageUrl;

        // 在图片加载完成后获取其原始宽度和高度
        img.onload = function() {
            // 获取图片的原始宽度和高度
            var originalWidth = img.naturalWidth;
            var originalHeight = img.naturalHeight;
            var renderedWidth = img.clientWidth;
            var renderedHeight = img.clientHeight;

            // 将结果返回
            resolve({ width: originalWidth, height: originalHeight,renderedWidth:renderedWidth ,renderedHeight:renderedHeight});
        };

        // 如果加载出错，则返回错误信息
        img.onerror = function() {
            reject(new Error('无法加载图片'));
        };
    });
}
